
import './App.css';
import { Box, Stack,Divider,Grid,Paper } from '@mui/material';
import CRUD from './Pages/Crud';
function App() {
  return (
   <CRUD/>
   // <App/>
      // <Paper sx={{padding:'32px'}} elevation={6}>
      // <Stack 
      // sx={{border:'1px solid'}}
      //  direction='row' 
      //  spacing={2}
      //  divider={<Divider orientation='vertical' flexItem/>}>
        
      //   <Box component="div" sx={{
      //     backgroundColor: 'primary.main',
      //     width: '100px',
      //     height: '100px',
      //     padding: '16px',
      //     '&:hover': {
      //       backgroundColor: 'secondary.light'
      //     }
      //   }}>wjbckdbcig</Box>

      //   <Box display='flex'
      //     height='100px'
      //     width='100px'
      //     bgcolor='success.main'
      //     p={2}
      //   >vdwvwervwr</Box>
      // </Stack>
      // <Grid container my={2} spacing={2}>
      //   <Grid item xs={12}  sm={6} >
      //     <Box bgcolor='primary.light' p={2}>Item1</Box>
      //   </Grid>
      //   <Grid item xs={12} sm={6} >
      //     <Box bgcolor='primary.light' p={2}>Item2</Box>
      //   </Grid>
      //   <Grid item xs={12} sm={6} >
      //     <Box bgcolor='primary.light' p={2}>Item3</Box>
      //   </Grid>
      //   <Grid item xs={12} sm={6} >
      //     <Box bgcolor='primary.light' p={2}>Item4</Box>
      //   </Grid>

      // </Grid>
      // </Paper>
      

   )
}

export default App;
